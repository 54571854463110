import createReactClass from 'create-react-class'
import React from 'react'
import "../../style/MainButton.css"

var MainButton = createReactClass({
    render: function () {
        return (
            <button className={this.props.classname} onClick={this.props.onClick} id={this.props.id}>
                <img src={this.props.image} alt="" />
                <p>{this.props.content}</p>
            </button>
        )
    }

});

export default MainButton
