export class Image {
    big: string;
    normal: string;
    small: string;
    alt: string;
    name: string;

    constructor() {
        this.big = "";
        this.normal = "";
        this.small = "";
        this.alt = "";
        this.name = "";
    }

    loadFromJson(jsonObject: any) {
        if (jsonObject) {
            if (jsonObject.big) {
                this.big = jsonObject.big;
            }
            if (jsonObject.normal) {
                this.normal = jsonObject.normal;
            }
            if (jsonObject.small) {
                this.small = jsonObject.small;
            }
            if (jsonObject.alt) {
                this.alt = jsonObject.alt;
            }
            if (jsonObject.name) {
                this.name = jsonObject.name;
            }
        }
    }

    toJason(): any {
        return {
            big: this.big,
            normal: this.normal,
            small: this.small,
            alt: this.alt,
            name: this.name,
        };
    }
}

export default Image;
