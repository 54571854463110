/* eslint-disable react/no-direct-mutation-state */
import React from 'react';
import '../../style/SingleOrderModal.css';
import MainButton from '../libs-common/MainButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBug, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import Order from '../model/order';
import { timeToHoursMinutesDate } from '../libs-common/DateTimeHelper';
import SingleOrderCart from './SingleOrderCart';
import enumRequestState from '../model/enums/request_state';
// import enumAdminType from '../model/enums/adminType';
import DebugObjectModal from './DebugObjectModal';
import enumOrderPayer from '../model/enums/orderPayer';
var createReactClass = require('create-react-class');

var SingleOrderModal = createReactClass({
    getInitialState: function () {
        //Copy object to the new one in case if user doesn't want to save changes.
        let _order = new Order();
        let enableEdit = true;
        if (this.props.order) {
            _order.loadFromJson(this.props.order.toJason());
            if (_order.state === enumRequestState.PENDING_FOR_DELIVERY) {
                // TODO: Move this to settings!
                _order.deliveryCost = 250;
                if (_order.cart.client.orderPayer !== enumOrderPayer.USER) {
                    _order.deliveryCost = 0;
                }
            }
            enableEdit = false;
        }
        const _client = _order.cart.client;
        return {
            order: _order,
            showOrderCancellationComment: false,
            enableEdit: enableEdit,
            showLog: false,
            showCart: false,
            client: _client,
            //_client.defaultDeliveryTime ? _client.defaultDeliveryTime : 0
            deliveryStartTime: 0,
            cancellationReason: "",
            timeIsValid: true,
            showDebugModal: false,
            deliveryCost: _order.deliveryCost,
            deliveryCostIsValid: true,
        };
    },
    componentDidMount() {
        document.body.style.overflow = 'hidden';
    },
    componentWillUnmount() {
        document.body.style.overflow = 'unset';
    },
    onInputChange: function (event) {
        const val = event.target.value;
        switch (event.target.id) {
            case "approx-delivery-time":
                this.setState({ deliveryStartTime: val })
                break;
            case "delivery-cost":
                this.setState({ deliveryCost: val })
                break;
            default:
                break;
        }
    },
    validateInputs: function () {
        let valid = false;
        if (this.state.deliveryStartTime > 0) {
            valid = true;
        }
        else {
            this.setState({ timeIsValid: false });
            valid = false;

        }
        if (this.state.deliveryCost < 0) {
            this.setState({ deliveryCostIsValid: false });
            valid = false;
        }

        return valid;
    },
    onButtonClick: function (id) {
        switch (id) {
            case "cancelOrder":
                this.setState({ showOrderCancellationComment: true });
                break;
            case "backCancel":
                this.setState({ showOrderCancellationComment: false });
                break;
            case "confirmCancel":
                if (this.state.cancellationReason.length > 5) {
                    this.state.order.cancellationReason = this.state.cancellationReason;
                    this.props.dbHandler.updateOrderDB(this.state.order, false, true, this.props.onOrderUpdateFinish);
                    this.props.onClose(true);
                }
                break;
            case "confirmOrder":
                this.state.order.estimatedDeliveryTime = this.state.deliveryStartTime;
                if (this.validateInputs()) {
                    this.state.order.estimatedDeliveryTime = this.state.deliveryStartTime;
                    this.state.order.deliveryCost = this.state.deliveryCost;
                    this.props.dbHandler.updateOrderDB(this.state.order, true, false, this.props.onOrderUpdateFinish);
                    this.props.order.state = this.state.order.state;
                    this.props.onClose(true);
                }
                break;
            case "finishOrder":
                this.props.dbHandler.updateOrderDB(this.state.order, true, false, this.props.onOrderUpdateFinish);
                this.props.order.state = this.state.order.state;
                this.props.onClose(true);
                break;
            case "back":
                this.props.onClose();
                break;
            case "showCart":
                this.setState({ showCart: true });
                break;
            case "showDebugModal":
                this.setState({ showDebugModal: true });
                break;
            default:
                this.props.onClose();
                break;
        }
    },
    getOrderPayerString: function (payer) {
        switch (payer) {
            case enumOrderPayer.USER:
                return "korisnik"
            case enumOrderPayer.CLIENT:
                return "lokal"
            case enumOrderPayer.WEBSITE:
                return "deMango"
            case enumOrderPayer.DELIVERY:
                return "dostavljač"

            default:
                return "Nepoznat"
        }
    },
    render: function () {
        let buttons;
        if (this.state.order.state === enumRequestState.PENDING_FOR_DELIVERY) {
            buttons = <>
                <MainButton
                    classname="modal-button red-button"
                    content="Otkaži"
                    id="cancelOrder"
                    onClick={this.onButtonClick.bind(this, "cancelOrder")}
                />

                <div className="processing-time">
                    <div className="processing-time-content">
                        <p className="single-order-info">Vreme dostave (min):</p>
                        <div className={(this.state.timeIsValid ? "single-info info-width " : "single-info info-width border-red ")}>
                            <input type="number" name="" id="approx-delivery-time" step="1"
                                value={this.state.deliveryStartTime} onChange={this.onInputChange} />
                        </div>
                    </div>
                    <div className="processing-time-content">
                        <p className="single-order-info">Cena dostave (RSD):</p>
                        <div className={(this.state.deliveryCostIsValid ? "single-info info-width " : "single-info info-width border-red ")}>
                            <input type="number" name="" id="delivery-cost" step="1"
                                value={this.state.deliveryCost} onChange={this.onInputChange} />
                        </div>
                    </div>
                </div>
                <MainButton
                    classname="modal-button green-button"
                    id="confirmOrder"
                    content="Prihvati"
                    onClick={this.onButtonClick.bind(this, "confirmOrder")}
                />
            </>
        }
        else if (this.state.order.state === enumRequestState.IN_PROGRESS) {
            buttons = <>
                <MainButton
                    classname="modal-button red-button"
                    content="Otkaži"
                    id="cancelOrder"
                    onClick={this.onButtonClick.bind(this, "cancelOrder")}
                />
                <MainButton
                    classname="modal-button green-button"
                    content="Završeno"
                    id="finishOrder"
                    onClick={this.onButtonClick.bind(this, "finishOrder")}
                />
            </>
        }
        else {
            buttons = <MainButton
                classname="modal-button"
                content="Nazad"
                id="back"
                onClick={this.onButtonClick.bind(this, "back")}
            />
        }

        if (this.state.showCart) {
            return <SingleOrderCart
                order={this.state.order}
                onClose={() => { this.setState({ showCart: false }) }}
                dbHandler={this.props.dbHandler}
                onlyShowCart={true} />
        }
        else if (this.state.showOrderCancellationComment) {
            return (
                <>
                    <div className="message-box-container">
                        <div className="message-box-content flex-column border-radius">
                            <div className="message-box-header">
                                <p>Otkaži zahtev</p>
                            </div>
                            <div className="message-box-body">
                                <textarea name="" id="" cols="" rows="" className="textarea-cancel-order"
                                    placeholder="Navedite razlog otkazivanja..."
                                    value={this.cancellationReason}
                                    onChange={(event) => { this.setState({ cancellationReason: event.target.value }) }}></textarea>
                            </div>
                            <div className="message-box-footer">
                                <MainButton
                                    classname="modal-button"
                                    content="Nazad"
                                    id="backCancel"
                                    onClick={this.onButtonClick.bind(this, "backCancel")}
                                />
                                <MainButton
                                    classname="modal-button red-button"
                                    content="Otkaži"
                                    id="confirmCancel"
                                    onClick={this.onButtonClick.bind(this, "confirmCancel")}
                                />
                            </div>
                        </div>
                    </div>
                </>
            )
        }
        else if (this.state.showDebugModal) {
            return (
                <DebugObjectModal
                    obj={this.state.order}
                    onClick={() => { this.setState({ showDebugModal: false }); }} />
            );
        }
        else {
            return (
                <div className="single-order-modal-container">
                    <div className="single-order-content">
                        <div className="single-order-header">
                            <div className="order-sender">
                                <h1>Lokal</h1>
                            </div>
                            <div className="order-receiver">
                                <h1>Kupac</h1>
                                <MainButton
                                    classname="modal-button close-modal"
                                    content="&times;"
                                    onClick={this.onButtonClick.bind(this, "back")}
                                />
                            </div>
                        </div>
                        <div className="single-order-body">
                            {this.state.order.cart && this.state.order.cart.client &&
                                this.state.order.cart.client.orderPayer !== enumOrderPayer.USER ?
                                <div className="paying-for-order">
                                    <p>Troškove dostave plaća {this.getOrderPayerString(this.state.order.cart.client.orderPayer)}</p>
                                </div> :
                                null}

                            <div className="order-comment">
                                <p className="single-order-info">KOMENTAR:</p>
                                <textarea className={this.state.order.comment.length === 0 ? "border-white" : "border-red"} name="" id="" cols="" rows="10" placeholder="Nema komentara..."
                                    value={
                                        this.state.order.comment + (this.state.order.cancellationReason ?
                                            "\r\n*************************************** \r\nRazlog otkazivanja: " +
                                            this.state.order.cancellationReason : "")
                                    }
                                    onChange={this.onInputChange}>
                                </textarea>
                            </div>
                            <div className="single-order-sender-receiver">
                                <div className="single-order-sender">
                                    <p className="single-order-info">{!this.state.order.cart.isEmpty() ? "LOKAL:" : "POŠILJALAC"}</p>
                                    <div className="single-info">
                                        <input type="text" value={this.state.order.senderAddress.name} onChange={this.onInputChange} />
                                    </div>
                                    <p className="single-order-info">{"ADRESA" + (!this.state.order.cart.isEmpty() ? " LOKALA" : "") + ":"}</p>
                                    <div className="single-info">
                                        <input type="text" value={this.state.order.senderAddress.address} onChange={this.onInputChange} />
                                    </div>
                                    <p className="single-order-info">{"TELEFON" + (!this.state.order.cart.isEmpty() ? " LOKALA" : "") + ":"}</p>
                                    <div className={(this.props.order &&
                                        this.props.order.cart &&
                                        this.props.order.cart.client &&
                                        this.props.order.cart.client.informMeByPhone) ?
                                        "single-info border-red" : "single-info"}>
                                        <input type="tel" value={this.state.order.senderAddress.phone} onChange={this.onInputChange} />
                                    </div>
                                    <p className="single-order-info">ZAKAZANO VREME:</p>
                                    <div className="single-info">
                                        <input type="text"
                                            value={timeToHoursMinutesDate(this.state.order.deliveryStartTime)}
                                            onChange={this.onInputChange} />
                                    </div>
                                </div>
                                <div className="single-order-receiver">
                                    <p className="single-order-info">KUPAC:</p>
                                    <div className="single-info">
                                        <input type="text" value={this.state.order.receiverAddress.name} onChange={this.onInputChange} />
                                    </div>
                                    <p className="single-order-info">ADRESA:</p>
                                    <div className="single-info">
                                        <input type="text" value={this.state.order.receiverAddress.address} onChange={this.onInputChange} />
                                    </div>
                                    <p className="single-order-info">TELEFON:</p>
                                    <div className="single-info">
                                        <input type="tel" value={this.state.order.receiverAddress.phone} onChange={this.onInputChange} />
                                    </div>
                                </div>
                            </div>
                            {/* <div className="log-view">
                                <div className="show-log" >
                                    <input type="checkbox" name="" id="view-log"
                                        onChange={(event) => { this.setState({ showLog: event.target.checked }) }} />
                                    <label htmlFor="view-log" className="unchecked">Vidi log</label>
                                    <label htmlFor="view-log" className="checked">Sakrij log</label>
                                    <label htmlFor="view-log">
                                        <img src="./images/down-arrow.svg" alt="" className="log-arrow-down" />
                                    </label>
                                </div>
                            </div>
                            <div className={(this.state.showLog ? "complete-order-log" : "complete-order-log-hidden")}>
                                
                                TODO: Not supported yet. Add support :) 
                                <div className="single-log">
                                    <p>Dospelo lokalu 14:38</p>
                                    <p>Danijel Janjic</p>
                                    <p>Milice Tomic 43</p>
                                </div>
                                <div className="single-log">
                                    <p>Dospelo dostava 14:48</p>
                                    <p>Danijel Janjic</p>
                                    <p>Milice Tomic 43</p>
                                </div>
                                <div className="single-log">
                                    <p>Zavrseno 15:38</p>
                                    <p>Danijel Janjic</p>
                                    <p>Milice Tomic 43</p>
                                </div>
                                <div className="single-log">
                                    <p>Zahtev u obradi 14:55</p>
                                    <p>Danijel Janjic</p>
                                    <p>Milice Tomic 43</p>
                                </div> 
                            </div>*/}
                        </div>
                        <div className="single-order-footer">
                            {buttons}
                            {!this.state.order.cart.isEmpty() ?
                                <MainButton
                                    classname="modal-button shopping-cart-button"
                                    content={<FontAwesomeIcon icon={faShoppingCart} />}
                                    id="showCart"
                                    onClick={this.onButtonClick.bind(this, "showCart")}
                                /> : null}
                            {(this.props.user && this.props.user.settings && this.props.user.settings.showDebug) ?
                                <MainButton
                                    classname="modal-button shopping-cart-button-bug"
                                    content={<FontAwesomeIcon icon={faBug} />}
                                    id="showDebugModal"
                                    onClick={this.onButtonClick.bind(this, "showDebugModal")}
                                /> : null}
                        </div>
                    </div>
                </div>
            )
        }
    }
});

export default SingleOrderModal;