import DBHandler from './database-handler.js';
import firebase from './firebase.js';
import Cookies from 'js-cookie';

var authStateChangeFunction = null;

class AuthenticationManager {

    static appDomain = this.getDomain();

    constructor(redirectionFunction) {
        this.dbHandler = new DBHandler();
        const token = AuthenticationManager.getSessionToken();
        firebase.auth().onAuthStateChanged(this.onAuthStateChanged);
        if (token) {
            this.loginWithToken(token, redirectionFunction);
        }
    }

    addAuthStateChangeFunction(func) {
        authStateChangeFunction = func;
    }

    static getDomain() {
        const hrefStr = String(window.location.href);
        if (hrefStr.includes("internal-portal-de-mango")) {
            return ".internal-portal-de-mango.rs";
        }
        else if (hrefStr.includes("localhost")) {
            return "localhost";
        }
        else {
            return "";
        }
    }

    isUserVerified(user_obj) {
        let user = firebase.auth().currentUser;
        if (user_obj) {
            if (user_obj.verified !== user.emailVerified) {
                user_obj.verified = user.emailVerified;
                this.dbHandler.updateAdminDB(user_obj);
            }
        }
        return user.emailVerified;
    }

    getCurrentUser() {
        return firebase.auth().currentUser;
    }

    isLoggedIn() {
        if (firebase.auth().currentUser) {
            return true;
        }
        else {
            return false;
        }
    }

    static updateSessionCookies(token) {
        const cookieName = "demango-internal-session";
        const path = "/";
        if (token) {
            Cookies.remove(cookieName, { domain: AuthenticationManager.appDomain, path: path });
            Cookies.set(cookieName, token, { domain: AuthenticationManager.appDomain, path: path });
        }
        else {
            Cookies.remove(cookieName, { domain: AuthenticationManager.appDomain, path: path });
        }
    }

    static getSessionToken() {
        const cookieName = "demango-internal-session";
        const path = "/";
        const cookie = Cookies.get(cookieName, { domain: AuthenticationManager.appDomain, path: path });
        return cookie;
    }

    async onAuthStateChanged(user_db) {
        if (user_db && user_db.email) {
            const token = AuthenticationManager.getSessionToken();
            if (!token) {
                firebase.auth().signOut();
            }
            let _dbHandler = new DBHandler();
            let admin_obj = await _dbHandler.getAdminByEmailDB(user_db.email);
            if (authStateChangeFunction) {
                authStateChangeFunction(admin_obj);
            }
            else {
                console.log("Auth state change function is not set!");
            }
        }
        else {
            if (authStateChangeFunction) {
                authStateChangeFunction(null);
            }
            else {
                console.log("Auth state change function is not set!");
            }
        }
    }

    async loginWithToken(token, redirectionFunction) {
        firebase.auth().signInWithCustomToken(token)
            .then((userCredential) => {
                //const user = userCredential.user;
            })
            .catch((error) => {
                if (error && error.code && error.code === "auth/invalid-custom-token") {
                    this.tryToRenewSessionCookie(redirectionFunction);
                }
                else {
                    console.log(error.code);
                    console.log(error.message);
                    AuthenticationManager.updateSessionCookies(null);
                    if (redirectionFunction) {
                        redirectionFunction();
                    }
                }
            });
    }

    async tryToRenewSessionCookie(redirectionFunction) {
        await this.dbHandler.getCustomToken(AuthenticationManager.updateSessionCookies);
        if (!AuthenticationManager.getSessionToken) {
            firebase.auth().signOut();
            if (redirectionFunction) {
                redirectionFunction();
            }
        }
        else {
            console.log("Session successfully updated.");
        }
    }

    async logOut(onLogoutFunction) {
        firebase.auth().signOut()
            .then((userCredential) => {
                AuthenticationManager.updateSessionCookies(null);
                if (onLogoutFunction) {
                    onLogoutFunction(false);
                }
                else {
                    console.log("On logout function is not set!");
                }
            })
            .catch((error) => {
                //var errorCode = error.code;
                //var errorMessage = error.message;
                if (onLogoutFunction) {
                    onLogoutFunction(false);
                }
                else {
                    console.log("On logout function is not set!");
                }
            });
    }

    async resendEmail() {
        var user = firebase.auth().currentUser;
        user.sendEmailVerification()
    }
}

export default AuthenticationManager;