/* eslint-disable react/no-direct-mutation-state */
import "../../style/DeliveryModal.css";
var createReactClass = require('create-react-class');

var ModalBodyComment = createReactClass({
    getInitialState: function () {
        return ({
            order: this.props.Order,
            comment: this.props.Order.comment
        });
    },
    onInputChange: function (event) {
        let val = event.target.value;
        let id = event.target.id;
        switch (id) {
            case "comment":
                this.state.order.comment = val;
                this.setState({ comment: val });
                break;
            default:
                break;
        }
    },
    render: function () {
        return (
            <div className="modal-body-form">
                <div className="modal-body-comment">
                    <h2>Komentar (opciono):</h2>
                    <label htmlFor="comment"></label>
                    <textarea onChange={this.onInputChange} name="message" id="comment" className="text-area" cols="30" rows="7"
                        placeholder="npr. dostaviti posle 19h" value={this.state.comment}></textarea>
                </div>
            </div>
        )
    }
})

export default ModalBodyComment;