import React from 'react';
import './App.css';
import StatusBar from './libs/components/StatusBar';
import PortalPage from './libs/components/PortalPage';
import Header from "./libs/components/Header";
import AuthenticationManager from './libs/firebase/authentication';
import enumAdminType from "./libs/model/enums/adminType";
import DBHandler from './libs/firebase/database-handler';
import Loader from './libs/libs-common/Loader';
import MessageBox from './libs/libs-common/MessageBox';
import DeliveryModal from './libs/components/DeliveryModal';
import NotificationManager from './libs/components/NotificationManager';
import AppConnectionState from './libs/libs-common/app-connection-state';
import AppVersionControl from './libs/components/AppVersionControl';
import packageJason from "../package.json";
import {dateToFullString} from './libs/libs-common/DateTimeHelper';
var createReactClass = require('create-react-class');

var App = createReactClass({
  getInitialState: function () {
    const _auth_manager = new AuthenticationManager(this.doRedirectionToHome);
    const _notificationManager = new NotificationManager("magic-marimba", 10000);
    _notificationManager.startAsync();
    _auth_manager.addAuthStateChangeFunction(this.onAuthStateChanged);
    const _dbHandler = new DBHandler();
    return {
      user: null,
      auth_manager: _auth_manager,
      auth_initialized: false,
      register: false,
      message: "",
      redirect: false,
      dbHandler: _dbHandler,
      pendingForClient: false,
      pendingForDelivery: false,
      inProgress: false,
      done: false,
      canceled: false,
      selectedDate: new Date(),
      selectedSort: "unseenOnTopI",
      ordersFromListener: [],
      showNewDeliveryModal: false,
      notificationManager: _notificationManager,
      disableSideMenu: true,
    };
  },
  onDbChangeEvent: function (orders) {
    this.setState({ ordersFromListener: orders });
    this.state.notificationManager.setNumberOfOrders(orders.length);
  },
  onAuthStateChanged: function (user) {
    if (user && this.validateUserPermissions(user)) {
      this.state.notificationManager.unmute();
      this.state.dbHandler.initializeListeners(user, this.onDbChangeEvent);
      if (user.type === enumAdminType.CLIENT) {
        this.setState({
          user: user,
          auth_initialized: true,
          pendingForClient: true,
        });
      }
      else {
        this.setState({
          user: user,
          auth_initialized: true,
          pendingForDelivery: true,
        });
      }
    }
    else {
      this.state.notificationManager.mute();
      if (!AuthenticationManager.getSessionToken()) {
        this.setState({
          user: user,
          auth_initialized: true,
          message: "Morate biti ulogovani da bi vam pristup stranici bio omogućen. Nalog mora sadržati odgovarajuće permisije!",
          redirect: true,
        });
      }

    }
  },
  validateUserPermissions: function (user) {
    return (user.type === enumAdminType.SUPER_ADMIN ||
      user.type === enumAdminType.CLIENT ||
      user.type === enumAdminType.DISPATCHER)
  },
  doRedirectionToHome: function () {
    if (AuthenticationManager.appDomain === "localhost") {
      window.location.href = "http://localhost:3001";
    }
    else {
      window.location.href = "https://internal-portal-de-mango.rs/";
    }
  },
  onStatusSelectionChange: function (statusObj) {
    this.setState(statusObj);
  },
  onNewDeliveryClick: function () {
    this.setState({ showNewDeliveryModal: true });
  },
  enableDisableSideMenu(disable = true) {
    this.setState({ disableSideMenu: disable });
  },
  render: function () {
    let content;
    if (!this.state.auth_initialized) {
      content = <><Loader /></>;
    }
    else if (this.state.redirect) {
      content =
        <>
          <MessageBox
            title="Upozorenje!"
            content={this.state.message}
            onClick={this.doRedirectionToHome}
          />;
        </>
    }
    else {
      content =
        <div className="app-row-order">
          <StatusBar
            user={this.state.user}
            onSelectionChange={this.onStatusSelectionChange}
            pendingForDelivery={this.state.pendingForDelivery}
            pendingForClient={this.state.pendingForClient}
            selectedDate={this.state.selectedDate}
            unreadCount={this.state.ordersFromListener.length}
            onNewOrderClick={this.onNewDeliveryClick}
            disabled={this.state.disableSideMenu} />
          <PortalPage dbHandler={this.state.dbHandler}
            user={this.state.user}
            ordersFromListener={this.state.ordersFromListener}
            enableDisableSideMenu={this.enableDisableSideMenu}
            selectedOrderStatus={
              {
                pendingForClient: this.state.pendingForClient,
                pendingForDelivery: this.state.pendingForDelivery,
                inProgress: this.state.inProgress,
                done: this.state.done,
                canceled: this.state.canceled,
                selectedDate: this.state.selectedDate,
                selectedSort: this.state.selectedSort,
              }} />
        </div>;
    }
    return (
      <div className="app-container">
        <AppVersionControl dbHandler={this.state.dbHandler} currentVersion={packageJason.buildDate} />
        <AppConnectionState />
        <div className="portal-page-container">
          <div className="portal-page-content">
            <Header user={this.state.user}
              onLogOut={this.state.auth_manager.logOut.bind(this, this.doRedirectionToHome)}
              notificationManager={this.state.notificationManager} />
            {this.state.showNewDeliveryModal ?
              <>
                <DeliveryModal
                  onClose={() => { this.setState({ showNewDeliveryModal: false }) }}
                  dbHandler={this.state.dbHandler}
                  user={this.state.user}
                />
              </> : null}
            {content}
          </div>
        </div>
        {/* TODO: Show build date on the left bottom part of the page. */}
        {(this.state.user && this.state.user.type === enumAdminType.SUPER_ADMIN) ?
          <p className="build-date">Build Date: <br/> {dateToFullString(packageJason.buildDate)} </p> : null}
      </div>
    );
  }
});

export default App;
