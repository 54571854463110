class NotificationManager {
    static _numberOfOrders;
    static _mute = false;
    static _started = false;
    static _playingTone = false;
    static _toneInterval = 1;
    static _tone = "default";
    static _playToneFunction = null;
    static _tonesMap = {
        "default": "mixkit_bell_notification_933.wav",
        "magic-marimba": "mixkit-magic-marimba-2820.wav",
        "musical-alert": "mixkit-musical-alert-notification-2309.wav",
        "orchestral-emergency": "mixkit-orchestral-emergency-alarm-2974.wav",
        "software-interface": "mixkit-software-interface-remove-2576.wav",
    };

    constructor(tone = "default", toneInterval = 3000) {
        NotificationManager._numberOfOrders = 0;
        NotificationManager._toneInterval = toneInterval;
        NotificationManager._tone = tone;
    }

    startAsync() {
        if (!NotificationManager._started)
            if (!NotificationManager._started) {
                this._notificationLoop();
            }
    }

    stopAsync() {
        NotificationManager._started = false;
    }

    mute() {
        NotificationManager._mute = true;
    }

    unmute() {
        NotificationManager._mute = false;
    }

    playTone() {
        NotificationManager._playTone(this.tone);
    }

    setTone(tone) {
        this.tone = tone
    }

    setNumberOfOrders(num) {
        if (num > NotificationManager._numberOfOrders) {
            NotificationManager._playTone(this.tone);
        }
        NotificationManager._numberOfOrders = num;

    }

    assignPlayFunction(func) {
        NotificationManager._playToneFunction = func;
    }

    static _playTone(tone) {
        const tonePath = NotificationManager._tonesMap[tone];
        if (tonePath) {
            if (!NotificationManager._playingTone) {
                NotificationManager._playingTone = true;
                if (NotificationManager._playToneFunction) {
                    NotificationManager._playToneFunction(tonePath);
                }
                else {
                    console.log("Play tone function is not assigned!");
                }


                NotificationManager._playingTone = false;
            }
        }
        else {
            console.log("Defined tone does not exist!");
        }
    }

    async _notificationLoop() {
        // Wait for a while to shut down all previously started async loops 
        // and first to skip if start is called multiply times.
        NotificationManager._started = true;
        await this.sleep(100);
        NotificationManager._started = false;
        await this.sleep(NotificationManager._toneInterval);
        await this.sleep(NotificationManager._toneInterval);
        NotificationManager._started = true;

        while (NotificationManager._started) {
            if (NotificationManager._numberOfOrders > 0 && !NotificationManager._mute) {
                NotificationManager._playTone(NotificationManager._tone);
            }
            await this.sleep(NotificationManager._toneInterval);
        }
    }

    async sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
}

export default NotificationManager;