/* eslint-disable no-unused-vars */
/* eslint-disable require-jsdoc */
import Address from "./address";
import WorkingTime from "./workingTime";
import Menu from "./menu";
import Image from "./image";
import enumOrderPayer from "./enums/orderPayer";
/**
 * Placeholder class for Address attributes.
 */
export class Client {
  id: string;
  index: number;
  showOnWebsite: boolean;
  name: string;
  type: string;
  types: {};
  locations: Address[];
  img: Image;
  headerImg: Image;
  sideMenuImg: Image;
  workingTime: WorkingTime[];
  menus: { [id: number]: Menu; };
  enablePromotion: boolean;
  info: string;
  defaultOrderTime: number;
  defaultDeliveryTime: number;
  informMeByPhone: boolean;
  isOverloaded: boolean;
  packingFee: number;
  orderPayer: enumOrderPayer;
  minimumDeliveryCost: number;

  constructor() {
    this.id = "";
    this.index = 0;
    this.showOnWebsite = false;
    this.name = "";
    this.type = "";
    this.types = {};
    this.locations = [];
    this.img = new Image();
    this.headerImg = new Image();
    this.sideMenuImg = new Image();
    this.workingTime = [];
    this.menus = {};
    this.enablePromotion = false;
    this.info = "";
    this.defaultDeliveryTime = 0;
    this.defaultOrderTime = 0;
    this.informMeByPhone = false;
    this.isOverloaded = false;
    this.packingFee = 0;
    this.orderPayer = enumOrderPayer.USER;
    this.minimumDeliveryCost = 0;
  }

  isEmpty() {
    return (!(this.name && this.menus))
  }

  addMenu(item: Menu) {
    this.menus[item.index] = item;
  }

  loadFromJson(jsonObject: any) {
    if (jsonObject) {
      if (jsonObject.id) {
        this.id = jsonObject.id;
      }
      if (jsonObject.index) {
        this.index = jsonObject.index;
      }
      if (jsonObject.showOnWebsite) {
        this.showOnWebsite = jsonObject.showOnWebsite;
      }
      if (jsonObject.name) {
        this.name = jsonObject.name;
      }
      if (jsonObject.type) {
        this.type = jsonObject.type;
      }
      if (jsonObject.types) {
        this.types = jsonObject.types;
      }
      if (jsonObject.locations) {
        this.locations = [];
        jsonObject.locations.forEach((addressJSON: {}) => {
          const address = new Address();
          address.loadFromJson(addressJSON);
          this.locations.push(address);
        });
      }
      if (jsonObject.img) {
        let img = new Image();
        img.loadFromJson(jsonObject.img);
        this.img = img;
      }
      if (jsonObject.headerImg) {
        let img = new Image();
        img.loadFromJson(jsonObject.headerImg);
        this.headerImg = img;
      }
      if (jsonObject.sideMenuImg) {
        let img = new Image();
        img.loadFromJson(jsonObject.headerImg);
        this.headerImg = img;
      }
      if (jsonObject.working_time) {
        this.workingTime = [];
        jsonObject.working_time.forEach((wTimeJSON: {}) => {
          const wTime = new WorkingTime();
          wTime.loadFromJson(wTimeJSON);
          this.workingTime.push(wTime);
        });
      }
      if (jsonObject.enable_promotion) {
        this.enablePromotion = jsonObject.enable_promotion;
      }
      if (jsonObject.menus) {
        // this.menus = jsonObject.menus;
      }
      if (jsonObject.info) {
        this.info = jsonObject.info;
      }
      if (jsonObject.defaultOrderTime) {
        this.defaultOrderTime = jsonObject.defaultOrderTime;
      }
      if (jsonObject.defaultDeliveryTime) {
        this.defaultDeliveryTime = jsonObject.defaultDeliveryTime;
      }
      if (jsonObject.informMeByPhone) {
        this.informMeByPhone = jsonObject.informMeByPhone;
      }
      if (jsonObject.isOverloaded) {
        this.isOverloaded = jsonObject.isOverloaded;
      }
      if (jsonObject.packingFee) {
        this.packingFee = jsonObject.packingFee;
      }
      if (jsonObject.orderPayer) {
        this.orderPayer = jsonObject.orderPayer;
      }
      if (jsonObject.minimumDeliveryCost) {
        this.minimumDeliveryCost = jsonObject.minimumDeliveryCost;
      }
    }
  }

  toJason(): any {
    const locationsJson: Array<{}> = [];
    this.locations.forEach((location) => {
      locationsJson.push(location.toJason());
    });
    const wTimesJSON: Array<{}> = [];
    this.workingTime.forEach((wTime) => {
      wTimesJSON.push(wTime.toJason());
    });
    return {
      id: this.id,
      index: this.index,
      showOnWebsite: this.showOnWebsite,
      name: this.name,
      type: this.type,
      types: this.types,
      locations: locationsJson,
      img: this.img.toJason(),
      headerImg: this.headerImg.toJason(),
      sideMenuImg: this.sideMenuImg.toJason(),
      working_time: wTimesJSON,
      enable_promotion: this.enablePromotion,
      info: this.info,
      defaultDeliveryTime: this.defaultDeliveryTime,
      defaultOrderTime: this.defaultOrderTime,
      informMeByPhone: this.informMeByPhone,
      isOverloaded: this.isOverloaded,
      packingFee: this.packingFee,
      orderPayer: this.orderPayer,
      minimumDeliveryCost: this.minimumDeliveryCost,
      // menus: this.menus,
    };
  }
}

export default Client;
