/* eslint-disable no-unused-vars */
export enum enumRequestState {
  PENDING_FOR_CLIENT = 1,
  PENDING_FOR_DELIVERY = 2,
  IN_PROGRESS = 3,
  DONE = 4,
  CANCELED = 5,
}

export default enumRequestState;
