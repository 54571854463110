import logo from '../../background-images/loaderlogo.svg';
import '../../style/Loader.css';

function Loader() {
    return (
        <div className="loader-container">
            <div className="loader-header">
                <div class="triple-spinner"></div>
                <img src={logo} alt="deMango logo" className="logo-icon" />
            </div>
        </div>
    );
}

export default Loader;