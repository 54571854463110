import '../../style/app-connection-state.css';
import React from 'react';
import { isRel } from '../firebase/firebase';

var createReactClass = require('create-react-class');

var AppConnectionState = createReactClass({
    getInitialState: function () {
        return {
            hideMe: false,
        };
    },
    onClick: function () {
        this.setState({ hideMe: true });
        this.autoShow();
    },
    autoShow: function () {
        this.sleep(60000).then(() => { this.setState({ hideMe: false }); })
    },
    sleep: async function (ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    },
    render: function () {
        if (isRel() || this.state.hideMe) {
            return (null);
        }
        else {
            return (
                <>
                    <div className="login-page-testing-info">
                        TESTING! This app is connected to test DB!
                        <button onClick={this.onClick}>HideMe</button>
                        for a one minute!
                    </div>
                </>
            );
        }

    }
});

export default AppConnectionState;
