/* eslint-disable require-jsdoc */

/**
 * Placeholder class for Address attributes.
 */
export class Address {
  id: string;
  address: string;
  name: string;
  phone: string;
  /**
   * Empty Constructor
   */
  constructor() {
    this.id = "";
    this.address = "";
    this.name = "";
    this.phone = "";
  }

  loadFromJson(jsonObject: any) {
    if (jsonObject) {
      if (jsonObject.id) {
        this.id = jsonObject.id;
      }
      if (jsonObject.address) {
        this.address = jsonObject.address;
      }
      if (jsonObject.name) {
        this.name = jsonObject.name;
      }
      if (jsonObject.phone) {
        this.phone = jsonObject.phone;
      }
    }
  }

  toJason(): any {
    return {
      id: this.id,
      address: this.address,
      name: this.name,
      phone: this.phone,
    };
  }

  get Id(): string {
    if (!this.id) {
      this.generateAddressId();
    }
    return this.id;
  }

  generateAddressId(): string {
    const _address = this.address.toLowerCase();
    const _name = this.name.toLowerCase();
    const _phone = this.phone.toLowerCase();
    let retValue = _address + "_" + _name + "_" + _phone;
    retValue = retValue.replace(/ /g, "");
    retValue = retValue.replace(/[/|~`,.;:'"{}@^#$%=?&*()!<>+-]/g, "");
    this.id = retValue;
    return retValue;
  }
}

export default Address;
