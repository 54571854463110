/* eslint-disable no-unused-vars */
export enum enumAdminType {
  UNIDENTIFIED = 0,
  MODERATOR = 1,
  DISPATCHER = 2,
  CLIENT = 3,
  SUPER_ADMIN = 77,
}

export default enumAdminType;
