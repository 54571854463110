import '../../style/debug-object-modal.css';
import createReactClass from 'create-react-class';
import {dateToFullString} from '../libs-common/DateTimeHelper';

var DebugObjectModal = createReactClass({
    componentDidMount() {
        document.body.style.overflow = 'hidden';
    },
    componentWillUnmount() {
        document.body.style.overflow = 'unset';
    },
    makeWindowContent: function (obj) {
        let retValue = [];
        retValue.push(<span className="span-key">{"{"}</span>);
        retValue.push(<br />);
        this.unpackObjectToHtml(obj, retValue, 1);
        retValue.push(<span className="span-key">{"}"}</span>);
        return retValue;
    },
    unpackObjectToHtml: function (obj, retArray, tabSpaces = 0) {
        Object.keys(obj).forEach((objKey) => {
            if (typeof obj[objKey] === 'object' && obj[objKey] !== null) {
                // if object is Date.
                if (typeof obj[objKey].getMonth === 'function') {
                    retArray.push(<>
                        <span className="span-key">{this.generateSpaces(tabSpaces)}{objKey}: </span>
                        <span className="span-value-time">
                            {dateToFullString(obj[objKey])}
                        </span>
                    </>);
                    retArray.push(<br />);
                }
                else {
                    retArray.push(<span className="span-key">{this.generateSpaces(tabSpaces)}{objKey}: {"{"}</span>);
                    retArray.push(<br />);
                    this.unpackObjectToHtml(obj[objKey], retArray, tabSpaces + 1);
                    retArray.push(<span className="span-key">{this.generateSpaces(tabSpaces)} {"}"}</span>);
                    retArray.push(<br />);
                }
            }
            else if (typeof obj[objKey] === 'boolean') {
                retArray.push(<> <span className="span-key">{this.generateSpaces(tabSpaces)}{objKey}: </span> {obj[objKey] ?
                    <span className="span-value-bool">true</span> : <span className="span-value-bool">false</span>}</>);
                retArray.push(<br />);
            }
            else if (typeof obj[objKey] === 'string') {
                retArray.push(<>
                    <span className="span-key">{this.generateSpaces(tabSpaces)} {objKey}: </span>
                    <span className="span-value">"{obj[objKey]}"</span>
                </>);
                retArray.push(<br />);
            }
            else {
                retArray.push(<>
                    <span className="span-key">{this.generateSpaces(tabSpaces)} {objKey}: </span>
                    <span className="span-value-num">{obj[objKey]}</span>
                </>);
                retArray.push(<br />);
            }
        });
    },
    generateSpaces: function (multiplier) {
        let retObj = []
        for (let index = 0; index < multiplier; index++) {
            retObj.push(<>&nbsp;&nbsp;&nbsp;&nbsp;</>);
        }
        return retObj;
    },
    render: function () {
        return (
            <div className="debug-win-container">
                <div className="debug-win-content flex-column border-radius">
                    <div className="debug-win-header">
                        <p>Debug window</p>
                    </div>
                    <div className="debug-win-body">
                        <div className="debug-object-content">
                            <p>
                                {this.makeWindowContent(this.props.obj)}
                            </p>
                        </div>
                    </div>
                    <div className="debug-win-footer">
                        <button className="btn-style-white" onClick={this.props.onClick}>U redu</button>
                    </div>
                </div>
            </div>
        )
    }
});

export default DebugObjectModal;