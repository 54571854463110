import MenuItem from "./menuItem";
import Client from "./client";

class StoreCart {
    private _id: string;
    private _client: Client;
    private _items: Array<MenuItem>;

    constructor() {
        this._id = "";
        this._client = new Client();
        this._items = [];
    }

    get id(): string {
        return this._id;
    }
    set id(value: string) {
        this._id = value;
    }

    get client(): Client {
        return this._client;
    }
    set client(value: Client) {
        this._client = value;
    }

    get items(): Array<MenuItem> {
        return this._items;
    }
    set items(value: Array<MenuItem>) {
        this._items = value;
    }

    addItem(item: MenuItem, quantity = 1) {
        if (item) {
            const copyItem = new MenuItem();
            copyItem.loadFromJson(item.toJson());
            copyItem.itemCount = quantity;
            this._items.push(copyItem);
        }
    }
    removeItem(item: MenuItem) {
        const index: number = this._items.indexOf(item);
        if (index !== -1) {
            this._items.splice(index, 1);
        }
    }

    emptyCart() {
        this._items = [];
    }

    isEmpty() {
        return (!(this._items && this._items.length > 0));
    }

    getCurrentPrice() {
        let price: number = 0;
        this._items.forEach((item: MenuItem) => {
            price = Number(price) + Number(item.getCurrentPrice());
        });
        return price.toFixed(2);
    }

    getItemsCount(): number {
        let count: number = 0
        this._items.forEach((menuItem: MenuItem) => {
            count += menuItem.itemCount;
        });

        return count;
    }

    toJson() {
        let items_json: Array<{}> = [];
        this._items.forEach((item: MenuItem) => {
            items_json.push(item.toJson());
        });

        return {
            id: this._id,
            client: this._client.toJason(),
            items: items_json,
            price: this.getCurrentPrice()
        }
    }

    loadFromJson(jsonObject: any) {
        if (jsonObject) {
            if (jsonObject.id) {
                this.id = jsonObject.id;
            }
            if (jsonObject.client) {
                const tempClient = new Client()
                tempClient.loadFromJson(jsonObject.client)
                this._client = tempClient;
            }
            if (jsonObject.items) {
                this._items = [];
                jsonObject.items.forEach((itemJson: {}) => {
                    const itemObj = new MenuItem();
                    itemObj.loadFromJson(itemJson);
                    this.addItem(itemObj, itemObj.itemCount);
                });
            }
        }
    }
}

export default StoreCart