import '../../style/Sucess.css';

function Error() {
    return (
        <div className="error-meesage">
            <img src="./images/robotics.svg" alt="Došlo je do greške" />
            <h3>Došlo je do greške!</h3>
        </div>
    )
}

export default Error;