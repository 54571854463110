/* eslint-disable react/no-direct-mutation-state */
import React from 'react'
import { ThemeProvider } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { createTheme } from '@material-ui/core/styles';
import "../../style/DeliveryModal.css";
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
var createReactClass = require('create-react-class');

const fTheme = createTheme({
    palette: {
        primary: {
            light: 'white',
            main: '#474747',
            dark: '#474747',
            contrastText: '#ffffff'
        },

        secondary: {
            light: 'white',
            main: 'rgb(247, 20, 20)',
            dark: 'rgb(247, 20, 20)',
            contrastText: '#ffffff'
        },
    }
});


var ModalBodyReceiver = createReactClass({
    getInitialState: function () {
        return ({
            order: this.props.Order,
            name: this.props.Order.receiverAddress.name,
            address: this.props.Order.receiverAddress.address,
            phone: this.props.Order.receiverAddress.phone,
            email: this.props.Order.receiverAddress.email,
            wrongAddress: false,
            wrongPhone: false,
            wrongName: false,
        });
    },
    componentDidUpdate: function (prevProps) {
        if (this.props.wrongAddress !== prevProps.wrongAddress ||
            this.props.wrongName !== prevProps.wrongName ||
            this.props.wrongPhone !== prevProps.wrongPhone) {
            this.setState({
                wrongAddress: this.props.wrongAddress,
                wrongPhone: this.props.wrongPhone,
                wrongName: this.props.wrongName,
            });
        }
    },
    onInputChange: function (event) {
        let val = event.target.value;
        let id = event.target.id;
        switch (id) {
            case "name":
                this.state.order.receiverAddress.name = val;
                this.setState({ name: val });
                break;
            case "address":
                this.state.order.receiverAddress.address = val;
                this.setState({ address: val });
                break;
            case "phone":
                this.state.order.receiverAddress.phone = val;
                this.setState({ phone: val });
                break;
            case "email":
                this.state.order.receiverAddress.email = val;
                this.setState({ email: val });
                break;
            case "no-need-for-sender":
                this.props.onSkipSenderChange(event.target.checked)
                break;
            default:
                break;
        }
    },
    onAddressSelect: function (event) {
        if (event.target.selectedOptions && event.target.selectedOptions[0]) {
            const addressId = event.target.selectedOptions[0].value;
            if (addressId !== "newAddress") {
                let addressObj = null;
                this.props.addresses.forEach(address => { //TODO: Maybe make hash set and make this code faster
                    if (address.id === addressId) {
                        addressObj = address;
                    }
                });
                if (addressObj) {
                    this.state.order.receiverAddress.name = addressObj.name;
                    this.state.order.receiverAddress.address = addressObj.address;
                    this.state.order.receiverAddress.phone = addressObj.phone;
                    this.setState({
                        name: addressObj.name,
                        address: addressObj.address,
                        phone: addressObj.phone,
                    });
                }
                else {
                    this.state.order.receiverAddress.name = "";
                    this.state.order.receiverAddress.address = "";
                    this.state.order.receiverAddress.phone = "";
                    this.setState({
                        name: "",
                        address: "",
                        phone: "",
                    });
                }
            }
            else {
                this.state.order.receiverAddress.name = "";
                this.state.order.receiverAddress.address = "";
                this.state.order.receiverAddress.phone = "";
                this.setState({
                    name: "",
                    address: "",
                    phone: "",
                });
            }
        }
    },
    render: function () {
        return (
            <>
                <div className="modal-body-form">
                    {(this.props.showAddresses && this.props.addresses && Array.isArray(this.props.addresses)) ?
                        <ModalBodyCombobox
                            addresses={this.props.addresses}
                            loadingAddresses={this.props.loadingAddresses}
                            onClick={this.onAddressSelect}
                        /> : null}
                    <h2>Primalac</h2>
                    <ThemeProvider theme={fTheme}>
                        <form action="" id="sender-form" className="modal-sender-form">
                            <TextField onChange={this.onInputChange} id="name"
                                label="Ime i prezime" variant="outlined"
                                color="primary"
                                value={this.state.name} autoFocus="true" error={(this.props.wrongName ? true : false)} />
                            <TextField onChange={this.onInputChange} id="address"
                                label="Ulica i broj/sprat/broj stana" variant="outlined"
                                color="primary"
                                value={this.state.address} error={(this.props.wrongAddress ? true : false)} />
                            <TextField onChange={this.onInputChange} id="phone"
                                color="primary"
                                label="Broj telefona" variant="outlined"
                                value={this.state.phone} error={(this.props.wrongPhone ? true : false)} />
                        </form>
                    </ThemeProvider>
                </div>
                <input type="checkbox" name="" id="no-need-for-sender" onChange={this.onInputChange}
                    checked={this.state.order.senderAddress.phone === "unknown"} />
                <label htmlFor="no-need-for-sender" className="sender-label">Označiti ako pošiljalac nije potreban</label>
                <label htmlFor="no-need-for-sender" className="input-black-box sender-label">
                    <i><FontAwesomeIcon icon={faCheck} /></i>
                </label>
            </>
        )
    }
})

var ModalBodyCombobox = createReactClass({
    render: function () {
        return (
            <div className="modal-body-form-combobox">
                <form action="" className="">
                    <label for="address-combo">Moje adrese:</label>
                    {this.props.loadingAddresses ?
                        <> Učitavanje...</> :
                        <>
                            {this.props.addresses.length > 0 ?
                                <> <select name="address-combo" id="address-combo" onClick={this.props.onClick.bind(this)}>
                                    <option value="newAddress"> Nova adresa</option>
                                    {this.props.addresses.map((address, index) => {
                                        return (
                                            <option value={address.id} >{address.name + ", " + address.address}</option>
                                        );
                                    })}
                                </select>
                                </> :
                                <> Nemate sačuvanih adresa.</>}
                        </>}
                </form>
            </div>
        );
    }
});

export default ModalBodyReceiver