
function timeToHoursAndMinutes(dateTime) {
    if (dateTime) {
        const date = new Date(dateTime);
        return "" +
            (date.getHours() < 10 ? ("0" + date.getHours()) : date.getHours()) +
            ":" +
            (date.getMinutes() < 10 ? ("0" + date.getMinutes()) : date.getMinutes());
    }
    else {
        console.log("Date number cannot be null!");
        return "Date Error"
    }
}

function timeToHoursMinutesDate(dateTime) {
    if (dateTime) {
        const date = new Date(dateTime);
        const mount = Number(date.getMonth()) + 1;
        return "" +
            (date.getHours() < 10 ? ("0" + date.getHours()) : date.getHours()) +
            ":" +
            (date.getMinutes() < 10 ? ("0" + date.getMinutes()) : date.getMinutes()) +
            "   " +
            (date.getDate() < 10 ? ("0" + date.getDate()) : date.getDate()) +
            "." +
            (mount < 10 ? ("0" + mount) : mount) +
            "." +
            (date.getFullYear() < 10 ? ("0" + date.getFullYear()) : date.getFullYear()) +
            ".";
    }
    else {
        console.log("Date number cannot be null!");
        return "Date Error"
    }
}

function timeToDate(dateTime) {
    if (dateTime) {
        const date = new Date(dateTime);
        const mount = Number(date.getMonth()) + 1;
        return "" +
            (date.getDate() < 10 ? ("0" + date.getDate()) : date.getDate()) +
            ". " +
            (mount < 10 ? ("0" + mount) : mount) +
            ". " +
            (date.getFullYear() < 10 ? ("0" + date.getFullYear()) : date.getFullYear()) +
            ".";
    }
    else {
        console.log("Date number cannot be null!");
        return "Date Error"
    }
}

function timeToDatePickerFormat(dateTime) {
    if (dateTime) {
        const date = new Date(dateTime);
        const mount = Number(date.getMonth()) + 1;
        return "" +
            (date.getFullYear() < 10 ? ("0" + date.getFullYear()) : date.getFullYear()) +
            "-" +
            (mount < 10 ? ("0" + mount) : mount) +
            "-" +
            (date.getDate() < 10 ? ("0" + date.getDate()) : date.getDate()) +
            "";
    }
    else {
        console.log("Date number cannot be null!");
        return "Date Error"
    }
}

function dateToEuropeanFormat(dateTime) {
    if (dateTime) {
        const date = new Date(dateTime);
        const mount = Number(date.getMonth()) + 1;
        return "" +
            (date.getDate() < 10 ? ("0" + date.getDate()) : date.getDate()) +
            ". " +
            (mount < 10 ? ("0" + mount) : mount) +
            ". " +
            (date.getFullYear() < 10 ? ("0" + date.getFullYear()) : date.getFullYear()) +
            ".";
    }
    else {
        console.log("Date number cannot be null!");
        return "Date Error"
    }
}

function dateToFullString(dateTime) {
    if (dateTime) {
        const date = new Date(dateTime);
        const mount = Number(date.getMonth()) + 1;
        return "" +
            (date.getDate() < 10 ? ("0" + date.getDate()) : date.getDate()) +
            "." +
            (mount < 10 ? ("0" + mount) : mount) +
            "." +
            (date.getFullYear() < 10 ? ("0" + date.getFullYear()) : date.getFullYear()) +
            ". " +
            (date.getHours() < 10 ? ("0" + date.getHours()) : date.getHours()) +
            ":" +
            (date.getMinutes() < 10 ? ("0" + date.getMinutes()) : date.getMinutes());
    }
    else {
        console.log("Date number cannot be null!");
        return "Date Error"
    }
}

function getDateFromPickerString(pickerValue) {
    // Picker format: 2021-07-20
    const retDate = new Date();
    const pickerValuesArray = pickerValue.split("-")
    if (pickerValuesArray[0] && pickerValuesArray[1] && pickerValuesArray[2]) {
        retDate.setFullYear(Number(pickerValuesArray[0]));
        retDate.setMonth(Number(pickerValuesArray[1]) - 1);
        retDate.setDate(Number(pickerValuesArray[2]));
        return retDate;
    }
    else {
        console.log("Picker value is not in valid format!");
        return retDate;
    }
}

export function getDayIntervalFromDate(dateTime) {
    var startDate = new Date(dateTime);
    var endDate = new Date(dateTime);
    startDate.setDate(startDate.getDate() - 1);
    startDate.setHours(23);
    startDate.setMinutes(59);
    startDate.setSeconds(59);
    endDate.setDate(endDate.getDate() + 1);
    endDate.setHours(0);
    endDate.setMinutes(0);
    endDate.setSeconds(0);
    return { startDate: startDate.getTime(), endDate: endDate.getTime() }
}

export {
    timeToHoursAndMinutes, timeToHoursMinutesDate, timeToDate, timeToDatePickerFormat, dateToEuropeanFormat,
    dateToFullString, getDateFromPickerString, getDayIntervalFromDate as default
}