import React from 'react';
import '../../style/StatusBar.css';
import { getDateFromPickerString, dateToEuropeanFormat, timeToDatePickerFormat } from '../libs-common/DateTimeHelper';
import MainButton from '../libs-common/MainButton';
import enumAdminType from '../model/enums/adminType';
import enumRequestState from '../model/enums/request_state';
var createReactClass = require('create-react-class');

var StatusBar = createReactClass({
    getInitialState: function () {
        let retObject = {
            pendingForClient: this.props.pendingForClient,
            pendingForDelivery: this.props.pendingForDelivery,
            inProgress: false,
            done: false,
            canceled: false,
            selectedDate: this.props.selectedDate,
            selectedSort: "unseenOnTop",
            decreasing: true,
            unreadCount: this.props.unreadCount,
            disabled: this.props.disabled,
        };
        // TODO: Add more sort values for delivery time, etc...
        retObject.sortValues = [
            { value: "unseenOnTop", name: "Neotvorene" },
            { value: "arrivedTime", name: "Primljeno vreme" },
            { value: "state", name: "Stanje dostave" },
        ];

        if (this.props.user.type === enumAdminType.CLIENT) {
            retObject.pendingForClientName = "Dospeli zahtevi";
            retObject.pendingForDeliveryName = "Čeka se dostava";
            retObject.showUnreadClient = true;
            retObject.showUnreadOther = false;
        }
        else {
            retObject.pendingForClientName = "Dospelo klijentu";
            retObject.pendingForDeliveryName = "Dospelo dostava";
            retObject.showUnreadClient = false;
            retObject.showUnreadOther = true;
        }
        return retObject;
    },
    componentDidUpdate: function (prevProps) {
        if (this.props.unreadCount !== prevProps.unreadCount) {
            this.setState({ unreadCount: this.props.unreadCount });
        }
        if (this.props.disabled !== prevProps.disabled) {
            this.setState({ disabled: this.props.disabled });
        }
    },
    onSelectionChange: function (state) {
        if (this.state.disabled) {
            return;
        }
        let retObj = {
            pendingForClient: this.state.pendingForClient,
            pendingForDelivery: this.state.pendingForDelivery,
            inProgress: this.state.inProgress,
            done: this.state.done,
            canceled: this.state.canceled,
            selectedDate: this.state.selectedDate,
        };
        switch (state) {
            case enumRequestState.PENDING_FOR_CLIENT:
                retObj.pendingForClient = !retObj.pendingForClient
                break;
            case enumRequestState.PENDING_FOR_DELIVERY:
                retObj.pendingForDelivery = !retObj.pendingForDelivery
                break;
            case enumRequestState.IN_PROGRESS:
                retObj.inProgress = !retObj.inProgress
                break;
            case enumRequestState.DONE:
                retObj.done = !retObj.done
                break;
            case enumRequestState.CANCELED:
                retObj.canceled = !retObj.canceled
                break;
            default:
                break;
        }
        this.setState(retObj, () => {
            if (this.props.onSelectionChange) {
                this.props.onSelectionChange(retObj);
            }
            else {
                console.log("On selection change function is not set!");
            }
        });
    },
    onDatePickerChange: function (event) {
        const retObj = { selectedDate: getDateFromPickerString(event.target.value) };
        this.setState(retObj);
        if (this.props.onSelectionChange) {
            this.props.onSelectionChange(retObj);
        }
        else {
            console.log("On selection change function is not set!");
        }
    },
    onSortSelectionChange: function (event) {
        const retObj = { selectedSort: (event.target.value + (this.state.decreasing ? "I" : "D")) }
        this.setState({ selectedSort: event.target.value });
        if (this.props.onSelectionChange) {
            this.props.onSelectionChange(retObj);
        }
        else {
            console.log("On selection change function is not set!");
        }
    },
    onCheckboxChange: function (event) {
        const retObj = { selectedSort: (this.state.selectedSort + (event.target.checked ? "I" : "D")) }
        if (event.target.id === "rotate") {
            this.setState({ decreasing: event.target.checked });
        }
        if (this.props.onSelectionChange) {
            this.props.onSelectionChange(retObj);
        }
        else {
            console.log("On selection change function is not set!");
        }
    },
    render: function () {
        return (
            <>
                <input type="checkbox" name="" id="status-open" />
                <label htmlFor="status-open" className="open-status-bar">
                    <img src="./images/status-bar/right-arrow.svg" alt="" />
                    {this.state.unreadCount > 0 ?
                        <div className="label-order-count">
                            <p>{this.state.unreadCount}</p>
                        </div> : null}
                </label>
                <div className="status-bar-container">
                    <div className="status-bar-content">
                        <MainButton
                            classname={("new-delivery " + (this.state.disabled ? "disabled-status-bar" : ""))}
                            content="Novi nalog za dostavu"
                            onClick={this.state.disabled ? null : this.props.onNewOrderClick}
                        />
                        <div className={("single-status-bar " +
                            (this.state.disabled ? "disabled-status-bar" : (this.state.pendingForClient ? "selected-status-bar" : "")))}
                            onClick={this.onSelectionChange.bind(this, enumRequestState.PENDING_FOR_CLIENT)}>
                            <img src="./images/status-bar/shoe-shop.svg" alt="" className="image-yellow" />
                            <img src="./images/status-bar/shoe-shop (2).svg" alt="" className="image-black" />
                            <p>{this.state.pendingForClientName}</p>
                            {(this.state.showUnreadClient && this.state.unreadCount > 0) ?
                                <div className="new-orders-count">
                                    <p>{this.state.unreadCount}</p>
                                </div> : null}
                        </div>
                        <div className={("single-status-bar " +
                            (this.state.disabled ? "disabled-status-bar" : (this.state.pendingForDelivery ? "selected-status-bar" : "")))}
                            onClick={this.onSelectionChange.bind(this, enumRequestState.PENDING_FOR_DELIVERY)}>
                            <img src="./images/status-bar/loading.svg" alt="" className="image-yellow" />
                            <img src="./images/status-bar/loading (1).svg" alt="" className="image-black" />
                            <p>{this.state.pendingForDeliveryName}</p>
                            {(this.state.showUnreadOther && this.state.unreadCount > 0) ?
                                <div className="new-orders-count">
                                    <p>{this.state.unreadCount}</p>
                                </div> : null}
                        </div>
                        <div className={("single-status-bar " +
                            (this.state.disabled ? "disabled-status-bar" : (this.state.inProgress ? "selected-status-bar" : "")))}
                            onClick={this.onSelectionChange.bind(this, enumRequestState.IN_PROGRESS)}>
                            <img src="./images/status-bar/express-delivery.svg" alt="" className="image-yellow" />
                            <img src="./images/status-bar/express-delivery (1).svg" alt="" className="image-black" />
                            <p>Zahtev u obradi</p>
                        </div>
                        <div className={("single-status-bar " +
                            (this.state.disabled ? "disabled-status-bar" : (this.state.done ? "selected-status-bar" : "")))}
                            onClick={this.onSelectionChange.bind(this, enumRequestState.DONE)}>
                            <img src="./images/status-bar/checkmark (1).svg" alt="" className="image-yellow" />
                            <img src="./images/status-bar/checkmark.svg" alt="" className="image-black" />
                            <p>Završeni zahtevi</p>
                        </div>
                        <div className={("single-status-bar " +
                            (this.state.disabled ? "disabled-status-bar" : (this.state.canceled ? "selected-status-bar" : "")))}
                            onClick={this.onSelectionChange.bind(this, enumRequestState.CANCELED)}>
                            <img src="./images/status-bar/cancel.svg" alt="" className="image-yellow" />
                            <img src="./images/status-bar/cancel (1).svg" alt="" className="image-black" />
                            <p>Otkazani zahtevi</p>
                        </div>
                        <div className={("date-picker " + (this.state.disabled ? "disabled-status-bar" : ""))}>
                            <label htmlFor="select-day">Porudžbine od:</label>
                            <form action="" className="date-pick-form">
                                <label htmlFor="select-day" id="date-label">{dateToEuropeanFormat(this.state.selectedDate)}</label>
                                < input type="date" name="select-day" id="select-day" min="2021-01-01"
                                    disabled={this.state.disabled}
                                    value={timeToDatePickerFormat(this.state.selectedDate)}
                                    onChange={this.onDatePickerChange} />
                            </form>
                        </div>
                        <div className={("sort-orders " + (this.state.disabled ? "disabled-status-bar" : ""))}>
                            <label htmlFor="sort">Sortiraj:</label>
                            <div className="form-container">
                                <form action="" className="form-sort">
                                    <select name="sort" id="sort" onChange={this.onSortSelectionChange} disabled={this.state.disabled}>
                                        {this.state.sortValues.map((sortValueObj) => {
                                            return (
                                                <option key={sortValueObj.value} value={sortValueObj.value}
                                                    className="sort-option">{sortValueObj.name}</option>
                                            );
                                        })}
                                    </select>
                                </form>
                                {this.state.disabled ? null :
                                    <>
                                        <input type="checkbox" id="rotate" checked={this.state.decreasing} onChange={this.onCheckboxChange} />
                                        <label htmlFor="rotate" className="rotate-label"><i><img src="/images/status-bar/up-arrow.svg" alt="" className="clicked" /></i></label>
                                    </>}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
});

export default StatusBar;