import { clearCache } from "clear-cache"
import moment from "moment";
import MessageBox from '../libs-common/MessageBox';

var createReactClass = require('create-react-class');

var AppVersionControl = createReactClass({
    getInitialState: function () {
        this.props.dbHandler.initializeVersionListener(this.onDbVersionChange);
        return {
            currentVersion: this.props.currentVersion,
            dbVersion: 0,
        };
    },
    onDbVersionChange: function (dbVersion) {
        this.setState({ dbVersion: dbVersion });
    },
    onClick: function () {
        clearCache(true);
    },
    render: function () {
        const momDBDateTime = moment(this.state.dbVersion);
        const momCurrentDateTime = moment(this.state.currentVersion);
        if (momDBDateTime.isAfter(momCurrentDateTime)) {
            return (
                <>
                    <MessageBox
                        title="Info"
                        content="Koristite staru verziju aplikacije. Kliknite 'U redu' za ponovno pokretanje. "
                        onClick={this.onClick} />
                </>
            );
        } else {
            return null;
        }
    }
});

export default AppVersionControl;
