/* eslint-disable react/no-direct-mutation-state */
import React from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { createTheme } from '@material-ui/core/styles';
import "../../style/DeliveryModal.css";
var createReactClass = require('create-react-class');

const fTheme = createTheme({
    palette: {
        primary: {
            light: 'white',
            main: '#474747',
            dark: '#474747',
            contrastText: '#ffffff'
        },

        secondary: {
            light: 'white',
            main: 'rgb(247, 20, 20)',
            dark: 'rgb(67, 70, 78)',
            contrastText: '#ffffff'
        }
    }
});

var ModalBodySender = createReactClass({
    getInitialState: function () {
        return ({
            order: this.props.Order,
            name: this.props.Order.senderAddress.name,
            address: this.props.Order.senderAddress.address,
            phone: this.props.Order.senderAddress.phone,
            // email: this.props.Order.senderAddress.email,
            wrongAddress: false,
            wrongPhone: false,
            wrongName: false,
        });
    },
    componentDidUpdate: function (prevProps) {
        if (this.props.wrongAddress !== prevProps.wrongAddress ||
            this.props.wrongName !== prevProps.wrongName ||
            this.props.wrongPhone !== prevProps.wrongPhone) {
            this.setState({
                wrongAddress: this.props.wrongAddress,
                wrongPhone: this.props.wrongPhone,
                wrongName: this.props.wrongName,
            });
        }
    },
    onInputChange: function (event) {
        let val = event.target.value;
        let id = event.target.id;
        switch (id) {
            case "name":
                this.state.order.senderAddress.name = val;
                this.setState({ name: val });
                break;
            case "address":
                this.state.order.senderAddress.address = val;
                this.setState({ address: val });
                break;
            case "phone":
                this.state.order.senderAddress.phone = val;
                this.setState({ phone: val });
                break;
            // case "email":
            //     this.state.order.sender.email = val;
            //     this.setState({ email: val });
            //     break;

            default:
                break;
        }
    },
    render: function () {
        return (
            <div className="modal-body-form">
                <h2>Pošiljalac</h2>
                <ThemeProvider theme={fTheme}>
                    <form action="" id="receiver-form" className="modal-receiver-form">
                        <TextField onChange={this.onInputChange} id="name"
                            label="Ime i prezime" variant="outlined" color="primary"
                            value={this.state.name} error={(this.props.wrongName ? true : false)} />
                        <TextField onChange={this.onInputChange} id="address"
                            label="Ulica i broj/sprat/broj stana" variant="outlined"
                            value={this.state.address} error={(this.props.wrongAddress ? true : false)} />
                        <TextField onChange={this.onInputChange} id="phone"
                            label="Broj telefona" variant="outlined"
                            value={this.state.phone} error={(this.props.wrongPhone ? true : false)} />
                    </form>
                </ThemeProvider>
            </div>
        )
    }
})

export default ModalBodySender